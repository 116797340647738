//
// IMPORTS
//

@import "reset";
@import "variables";
// Syntax highlighting @import is at the bottom of this file

/**************/
/* BASE RULES */
/**************/

html {
  font-size: 100%;
}

body {
	background: $background;
  font: 18px/1.4 $helvetica;
  color: $text;
}

.container {
  margin: 0 auto;
  max-width: 740px;
  padding: 0 10px;
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $helveticaNeue;
  color: $title;
  font-weight: bold;

  line-height: 1.7;
  margin: 1em 0 15px;
  padding: 0;

  @include mobile {
    line-height: 1.4;
  }
}

h1 {
  font-size: 30px;
  a {
    color: inherit;
  }
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
  color: $subtitle;
}

p {
  margin: 15px 0;
}

a {
  color: $link;
  text-decoration: none;
	cursor: pointer;
  &:hover, &:active {
    color: $link;
  }
}

ul, ol {
  margin: 15px 0;
  padding-left: 30px;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

ol ul, ul ol, ul ul, ol ol {
  margin: 0;
}

ul ul, ol ul {
  list-style-type: circle;
}

em, i {
  font-style: italic;
}

strong, b {
  font-weight: bold;
}

img {
  max-width: 100%;
  background-color: $item_background;
  padding: 10px;
}

// Fixes images in popup boxes from Google Translate
.gmnoprint img {
  max-width: none;
}

.date {
  font-style: italic;
  color: $subtitle;
}

// Specify the color of the selection
::-moz-selection {
  color: $black;
  background: $lightGray;
}
::selection {
  color: $black;
  background: $lightGray;
}

// Nicolas Gallagher's micro clearfix hack
// http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

/*********************/
/* LAYOUT / SECTIONS */
/*********************/

//
// .masthead
//

.wrapper-masthead {
  margin-bottom: 50px;
}

.masthead {
  padding: 20px 0;
  border-bottom: 1px solid $lightGray;

  @include mobile {
    text-align: center;
  }
}

.site-avatar {
  float: left;
  width: 70px;
  height: 70px;
  margin-right: 15px;

  @include mobile {
    float: none;
    display: block;
    margin: 0 auto;
  }

  img {
    border-radius: 5px;
  }
}

.site-info {
  float: left;

  @include mobile {
    float: none;
    display: block;
    margin: 0 auto;
  }
}

.site-name {
  margin: 0;
  color: $text;
  cursor: pointer;
  font-family: $helveticaNeue;
  font-weight: 300;
  font-size: 28px;
  letter-spacing: 1px;
}

.site-description {
  margin: -5px 0 0 0;
  color: $subtitle;
  font-size: 16px;

  @include mobile {
    margin: 3px 0;
  }
}

nav {
  float: right;
  margin-top: 23px; // @TODO: Vertically middle align
  font-family: $helveticaNeue;
  font-size: 18px;

  @include mobile {
    float: none;
    margin-top: 9px;
    display: block;
    font-size: 16px;
  }

  a {
    margin-left: 20px;
    color: $text;
    text-align: right;
    font-weight: 300;
    letter-spacing: 1px;

    @include mobile {
      margin: 0 10px;
      color: $link;
    }
  }
}

//
// .main
//

.posts > .post {
  padding-bottom: 2em;
  border-bottom: 1px solid $lightGray;
}

.posts > .post:last-child {
  padding-bottom: 1em;
  border-bottom: none;
}

.post {
  blockquote {
    margin: 1.8em .8em;
    border-left: 2px solid $subtitle;
    padding: 0.1em 1em;
    color: $subtitle;
    font-size: 22px;
    font-style: italic;
  }

  .comments {
    margin-top: 10px;
  }

  .read-more {
    text-transform: uppercase;
    font-size: 15px;
  }
}

.wrapper-footer {
  margin-top: 50px;
  border-top: 1px solid $highlight_border;
  border-bottom: 1px solid $highlight_border;
  background-color: $lightGray;
  width: 100%;
}

footer {
  padding: 20px 0;
  text-align: center;
}

.footer-text {
  display: block;
  font-size: 14px;
}

body {
  font-family: Helvetica, arial, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $background;
  padding: 0px; }

body > *:first-child {
  margin-top: 0 !important; }
body > *:last-child {
  margin-bottom: 0 !important; }

a {
  color: $link; }
a.absent {
  color: #cc0000; }
a.anchor {
  display: block;
  padding-left: 30px;
  margin-left: -30px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0; }

h1, h2, h3, h4, h5, h6 {
  margin: 20px 0 10px;
  padding: 0;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: text;
  position: relative; }

h1:hover a.anchor, h2:hover a.anchor, h3:hover a.anchor, h4:hover a.anchor, h5:hover a.anchor, h6:hover a.anchor {
  background: url("../../images/modules/styleguide/para.png") no-repeat 10px center;
  text-decoration: none; }

h1 tt, h1 code {
  font-size: inherit; }

h2 tt, h2 code {
  font-size: inherit; }

h3 tt, h3 code {
  font-size: inherit; }

h4 tt, h4 code {
  font-size: inherit; }

h5 tt, h5 code {
  font-size: inherit; }

h6 tt, h6 code {
  font-size: inherit; }

h1 {
  font-size: 28px;
  color: $title; }

h2 {
  font-size: 24px;
  border-bottom: 1px solid $highlight_border;
  color: $title; }

h3 {
  font-size: 18px; }

h4 {
  font-size: 16px; }

h5 {
  font-size: 14px; }

h6 {
  color: #777777;
  font-size: 14px; }

p, blockquote, ul, ol, dl, li, table, pre {
  margin: 15px 0; }

hr {
  background: transparent url("../../images/modules/pulls/dirty-shade.png") repeat-x 0 0;
  border: 0 none;
  color: $highlight_border;
  height: 4px;
  padding: 0; }

body > h2:first-child {
  margin-top: 0;
  padding-top: 0; }
body > h1:first-child {
  margin-top: 0;
  padding-top: 0; }
  body > h1:first-child + h2 {
    margin-top: 0;
    padding-top: 0; }
body > h3:first-child, body > h4:first-child, body > h5:first-child, body > h6:first-child {
  margin-top: 0;
  padding-top: 0; }

a:first-child h1, a:first-child h2, a:first-child h3, a:first-child h4, a:first-child h5, a:first-child h6 {
  margin-top: 0;
  padding-top: 0; }

h1 p, h2 p, h3 p, h4 p, h5 p, h6 p {
  margin-top: 0; }

li p.first {
  display: inline-block; }

ul, ol {
  padding-left: 30px; }

ul :first-child, ol :first-child {
  margin-top: 0; }

ul :last-child, ol :last-child {
  margin-bottom: 0; }

dl {
  padding: 0; }
  dl dt {
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    padding: 0;
    margin: 15px 0 5px; }
    dl dt:first-child {
      padding: 0; }
    dl dt > :first-child {
      margin-top: 0; }
    dl dt > :last-child {
      margin-bottom: 0; }
  dl dd {
    margin: 0 0 15px;
    padding: 0 15px; }
    dl dd > :first-child {
      margin-top: 0; }
    dl dd > :last-child {
      margin-bottom: 0; }

blockquote {
  border-left: 4px solid $highlight_border;
  padding: 0 15px;
  color: #777777; }
  blockquote > :first-child {
    margin-top: 0; }
  blockquote > :last-child {
    margin-bottom: 0; }

table {
  padding: 0; }
  table tr {
    border-top: 1px solid $highlight_border;
    background-color: white;
    margin: 0;
    padding: 0; }
    table tr:nth-child(2n) {
      background-color: $highlight_background; }
    table tr th {
      font-weight: bold;
      border: 1px solid $highlight_border;
      text-align: left;
      margin: 0;
      padding: 6px 13px; }
    table tr td {
      border: 1px solid $highlight_border;
      text-align: left;
      margin: 0;
      padding: 6px 13px; }
    table tr th :first-child, table tr td :first-child {
      margin-top: 0; }
    table tr th :last-child, table tr td :last-child {
      margin-bottom: 0; }

img {
  max-width: 100%; }

span.frame {
  display: block;
  overflow: hidden; }
  span.frame > span {
    border: 1px solid $highlight_border;
    display: block;
    float: left;
    overflow: hidden;
    margin: 13px 0 0;
    padding: 7px;
    width: auto; }
  span.frame span img {
    display: block;
    float: left; }
  span.frame span span {
    clear: both;
    color: #333333;
    display: block;
    padding: 5px 0 0; }
span.align-center {
  display: block;
  overflow: hidden;
  clear: both; }
  span.align-center > span {
    display: block;
    overflow: hidden;
    margin: 13px auto 0;
    text-align: center; }
  span.align-center span img {
    margin: 0 auto;
    text-align: center; }
span.align-right {
  display: block;
  overflow: hidden;
  clear: both; }
  span.align-right > span {
    display: block;
    overflow: hidden;
    margin: 13px 0 0;
    text-align: right; }
  span.align-right span img {
    margin: 0;
    text-align: right; }
span.float-left {
  display: block;
  margin-right: 13px;
  overflow: hidden;
  float: left; }
  span.float-left span {
    margin: 13px 0 0; }
span.float-right {
  display: block;
  margin-left: 13px;
  overflow: hidden;
  float: right; }
  span.float-right > span {
    display: block;
    overflow: hidden;
    margin: 13px auto 0;
    text-align: right; }

code, tt {
  margin: 0 2px;
  padding: 0 5px;
  white-space: nowrap;
  border: 1px solid $highlight_border;
  background-color: $highlight_background;
  border-radius: 3px; }

pre code {
  margin: 0;
  padding: 0;
  white-space: pre;
  border: none;
  background: transparent; }

.highlight pre {
  background-color: $highlight_background;
  border: 1px solid $highlight_border;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px; }

pre {
  background-color: $highlight_background;
  border: 1px solid $highlight_border;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px; }
  pre code, pre tt {
    background-color: transparent;
    border: none; }

.no_padding {
  padding: 0;
}

// Settled on moving the import of syntax highlighting to the bottom of the CSS
// ... Otherwise it really bloats up the top of the CSS file and makes it difficult to find the start
@import "highlights";
@import "svg-icons";
